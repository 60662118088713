<template>
  <v-overlay :value="progress" :color="color" absolute>
    <v-progress-circular
      indeterminate
      size="64"
      :class="{ 'absolute-loader': fixed }"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  props: {
    progress: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: "primary"
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.absolute-loader {
  position: fixed !important;
  transform: translate(-50%, -50%) !important;
  top: 50% !important;
  left: 50% !important;
}
</style>
