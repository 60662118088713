<template>
  <v-card :width="width" :height="height" class="mx-auto">
    <div class="d-flex align-center pa-3">
      <h3 class="text-h6 font-weight-bold">Ordenes realizadas</h3>
    </div>
    <ApexChart
      type="area"
      ref="chartSalesToday"
      :options="options"
      :series="dataChart"
    ></ApexChart>
    <app-absolute-loader
      :progress="loader"
      :color="$vuetify.theme.dark ? '' : 'grey'"
    ></app-absolute-loader>
  </v-card>
</template>

<script>
import ApexChart from "vue-apexcharts";
import AbsoluteLoader from "@/components/layout/AbsoluteLoader";

export default {
  components: {
    AppAbsoluteLoader: AbsoluteLoader,
    ApexChart
  },
  data() {
    return {
      // :: Chart options & data
      options: {
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "fill"
        },
        colors: ["#2FEAAD"],
        chart: {
          id: "vuechart-sales-today",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontFamily: "Poppins, Arial, sans-serif"
          }
        },
        stroke: {
          curve: "smooth"
        }
      },
      dataChart: [{ name: ["ventas"], data: [] }]
    };
  },
  props: {
    width: {
      default: "100%"
    },
    height: {
      default: "100%"
    },
    loader: {
      default: false
    }
  }
};
</script>
