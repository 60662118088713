<template>
  <v-card
    color="#385F73"
    dark
    height="80"
    class="d-flex align-center justify-space-between"
  >
    <div class="pa-3">
      <p class="ma-0 caption">Ordenes de hoy</p>
      <p class="headline ma-0">
        <v-progress-circular
          indeterminate
          size="24"
          v-if="loader"
        ></v-progress-circular
        ><span v-else>{{ totalOrders }}</span>
      </p>
    </div>
    <div class="pa-3">
      <v-icon x-large>move_to_inbox</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    totalOrders: {
      type: Number,
      default: () => 0
    },
    loader: {
      default: false
    }
  }
};
</script>

<style></style>
