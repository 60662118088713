<template>
  <v-card color="#385F73">
    <v-img
      src="../../assets/welcome-card.jpg"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
      height="173px"
    >
      <v-btn icon absolute top left x-small disabled>
        <v-img
          src="@/assets/ssencial-logo-white.svg"
          contain
          height="25px"
        ></v-img>
      </v-btn>
      <v-btn
        class="btn-close-absolute"
        absolute
        top
        right
        icon
        dark
        @click="setComponentUser('welcome')"
        ><v-icon>close</v-icon></v-btn
      >
      <div class="pa-3 pt-0">
        <h3 class="text-5 pb-1 font-weight-bold">Bienvenido</h3>

        <p class="caption ma-0 pb-1">
          Gracias por formar parte de SSENCIAL, siéntete libre de explorar todas
          las funcionalidades.
        </p>
        <v-btn block small color="primary" @click="dialogWelcome = true"
          >Explorar funcionalidades</v-btn
        >
      </div>
    </v-img>
    <v-dialog v-model="dialogWelcome" max-width="600">
      <v-card>
        <v-btn
          absolute
          top
          right
          icon
          style="z-index: 10;top: 2px;right: 2px;"
          dark
          @click="dialogWelcome = false"
          ><v-icon color="white">close</v-icon></v-btn
        >
        <v-carousel
          v-model="carouselPosition"
          :show-arrows="false"
          hide-delimiter-background
        >
          <v-carousel-item :key="0">
            <v-sheet color="black" height="100%" tile>
              <v-container class="fill-height">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-img
                      src="@/assets/ssencial-logo-white.svg"
                      contain
                      height="60px"
                      class="mb-10"
                    ></v-img>
                    <v-container>
                      <p class="text-subtitle-2">
                        A continuación te mostraremos algunas de muchas
                        características que puedes utilizar en tu sistema, poco
                        a poco irás descubriendo el potencial de esta gran
                        herramienta.
                      </p>
                      <v-btn outlined @click="carouselPosition = 1"
                        >Empezar <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="1">
            <v-sheet color="#778da9" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">Punto de venta</h3>
                    <v-icon size="90" class="mb-4">point_of_sale</v-icon>
                    <v-container>
                      <p class="text-subtitle-2">
                        Hemos integró un punto de venta en el cual podrás
                        realizar venta en físico de tus productos en cualquier
                        lugar que desees.
                      </p>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="2">
            <v-sheet color="#003049" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">Scaner integrado</h3>
                    <v-icon size="90" class="mb-4">qr_code_scanner</v-icon>
                    <v-container>
                      <p class="text-subtitle-2">
                        Hemos agilizado el proceso de lectura de código mediante
                        la ingresión de un scanner en la App, es tan sencillo
                        como sacar una foto 📸.
                      </p>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="3">
            <v-sheet color="indigo" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">Inventario en la nube</h3>
                    <v-icon size="90" class="mb-4">cloud</v-icon>
                    <v-container>
                      <p class="text-subtitle-2">
                        Tienes todos tus datos sincronizados en tiempo real, por
                        lo que siempre tendrás la última información registrada
                        en tu tienda.
                      </p>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="4">
            <v-sheet color="#e29578" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">Multiplataforma</h3>
                    <v-icon size="90" class="mb-4">devices</v-icon>
                    <v-container>
                      <p class="text-subtitle-2">
                        Nuestro sistema utiliza lo ultimo en tecnologia, podras
                        acceder desde una Computadora, Tablet, Celular y hasta
                        un Televisor, siéntete libre de acceder desde tu
                        dispositivo favorito.
                      </p>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="5">
            <v-sheet color="#5f5f5f" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">¿Sin conexión?</h3>
                    <v-icon size="90" class="mb-4">cloud_off</v-icon>
                    <v-container>
                      <p class="text-subtitle-2">
                        ¿Te pasa que todos estos sistemas necesitan internet
                        para funcionar?, pues tenemos la única solución en el
                        mercado ya que podrás trabajar con el sistema sin tener
                        una conexión a internet. Increíble no?
                      </p>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item :key="6">
            <v-sheet color="#d65656" height="100%" tile>
              <v-container class="fill-height overflow-y-auto">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h3 class="text-h4 mb-3">Tenemos aún más.</h3>
                    <p class="text-h4">😇</p>
                    <v-container>
                      <p class="text-subtitle-2">
                        Esto no es todo tenemos muchas características que
                        puedes utilizar en la app, si deseas explorar las demás
                        funcionalidades o profundizar en las que haz visto por
                        favor haz clic sobre el siguiente botón.
                      </p>
                    </v-container>
                    <v-btn
                      outlined
                      href="https://ayuda.ssencial.com/guia/punto-de-venta"
                      >GUIAS DE SSENCIAL APP</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogWelcome: false,
      carouselPosition: 0,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"]
    };
  },
  props: {
    totalOrders: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    ...mapActions("user", ["setComponentUser"])
  }
};
</script>

<style scoped>
.btn-close-absolute {
  top: 0 !important;
  right: 0 !important;
}
</style>
