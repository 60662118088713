<template>
  <div>
    <v-card color="#f05454" dark height="80" link to="/ajustes/sucursales">
      <v-list-item class="ma-0 pa-0">
        <v-list-item-content class="ma-0 pa-0">
          <div class="pl-3 pt-2">
            <p class="ma-0 caption">Sucursal</p>
            <div
              class="text-h6 ma-0 text-truncate"
              style="marginTop:-4px !important"
            >
              <span>{{ getCurrentBranchOffice.branch_office_name }}</span>
            </div>
            <div
              class="caption ma-0 text-truncate"
              style="marginTop:-5px !important"
            >
              <v-icon size="13" class="mr-1">location_on</v-icon
              >{{ getCurrentBranchOffice.location | locationFormat }}
            </div>
          </div>
        </v-list-item-content>

        <v-icon x-large class="pa-3">store</v-icon>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("business", ["getCurrentBranchOffice"])
  },
  filters: {
    locationFormat(location) {
      return `${location.street_name}, ${location.city_name}, ${location.state_name}, México, ${location.postal_code}`;
    }
  }
};
</script>
