<template>
  <v-card
    color="#4E478A"
    dark
    height="80"
    class="d-flex align-center justify-space-between"
    :disabled="orderID === 'NULL'"
    :to="{ name: 'orderDetail', params: { orderID } }"
  >
    <div>
      <div class="pa-3 pt-0 pb-0">
        <p class="ma-0 caption">Ultima venta</p>
        <div class="text-subtitle-1 ma-0">
          <v-progress-circular
            indeterminate
            size="30"
            v-if="loader"
          ></v-progress-circular
          ><span v-else
            >{{ totalProducts }}
            {{ totalProducts === 1 ? "Producto" : "Productos" }}</span
          >
        </div>
        <div
          class="caption ma-0"
          style="marginTop:-8px !important; color:#b9b9b9"
          v-if="!loader"
        >
          {{ created | toDateTransform }}
        </div>
      </div>
    </div>
    <div class="pa-3 pt-0 pb-0">
      <p class="ma-0 text-subtitle-1">
        <v-progress-circular
          indeterminate
          size="30"
          v-if="loader"
        ></v-progress-circular
        ><span v-else class="text-h6"
          >$ {{ fullPayment }} <span class="caption">MXN</span></span
        >
      </p>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    totalProducts: {
      type: Number,
      default: () => 0
    },
    fullPayment: {
      type: Number,
      default: () => 0
    },
    created: {
      default: () => null
    },
    loader: {
      default: true
    },
    orderID: {
      default: () => "NULL"
    }
  },
  filters: {
    toDateTransform(timeUnix) {
      if (timeUnix) return moment.unix(timeUnix).format("dddd DD [a las] h:mm");
      return "No hay ventas recientes";
    }
  }
};
</script>

<style></style>
