<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Dashboard
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="pb-1"
        v-if="components.welcome"
      >
        <AppWelcome />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="components.welcome ? 8 : 12"
        :lg="components.welcome ? 8 : 12"
        class="pt-0 pb-1"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            :lg="components.welcome ? 6 : 3"
            class="pt-1 pb-1"
          >
            <app-branch-office></app-branch-office>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            :lg="components.welcome ? 6 : 3"
            class="pt-1 pb-1"
          >
            <app-total-sold-today
              :totalSold="totalSold"
              :loader="loader.totalSold"
            ></app-total-sold-today>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            :lg="components.welcome ? 6 : 3"
            class="pt-1 pb-1"
          >
            <app-total-orders-today
              :totalOrders="totalOrders"
              :loader="loader.totalOrders"
            ></app-total-orders-today>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            :lg="components.welcome ? 6 : 3"
            class="pt-1 pb-1"
          >
            <app-last-order
              :totalProducts="lastOrder.total_products"
              :created="lastOrder.created"
              :fullPayment="lastOrder.total_sold"
              :loader="loader.lastOrder"
              :orderID="lastOrder.id"
            ></app-last-order>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="7" xl="7" class="pt-1 pb-1">
        <app-total-sales :loader="loader.chartSales"></app-total-sales>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="pt-1 pb-1">
        <v-row class="mb-2">
          <v-col cols="4" class="py-0">
            <v-card
              color="#778da9"
              dark
              height="80"
              link
              class="d-flex align-center justify-center"
              @click="$router.push({ name: 'sale' })"
            >
              <div class="text-center">
                <p class="ma-0 text-subtitle-1">Venta</p>
                <v-icon size="35" class="ma-2 mt-0">point_of_sale</v-icon>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-card
              color="indigo"
              dark
              height="80"
              link
              class="d-flex align-center justify-center"
              @click="$router.push({ name: 'inventory' })"
            >
              <div class="text-center">
                <p class="ma-0 text-subtitle-1">Inventario</p>
                <v-icon size="35" class="ma-2 mt-0">storage</v-icon>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-card
              color="#ef476f"
              dark
              height="80"
              link
              class="d-flex align-center justify-center"
              @click="$router.push({ name: 'register' })"
            >
              <div class="text-center">
                <p class="ma-0 text-subtitle-1">Ordenes</p>
                <v-icon size="35" class="ma-2 mt-0">request_page</v-icon>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <app-calendar></app-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import TotalSoldToday from "../components/dashboard/TotalSoldToday";
import TotalOrdersToday from "../components/dashboard/TotalOrdersToday";
import LastOrder from "../components/dashboard/LastOrder";
import TotalSales from "../components/dashboard/TotalSales";
import Calendar from "../components/dashboard/Calendar";
import Welcome from "../components/dashboard/Welcome";
import BranchOffice from "../components/dashboard/BranchOffice";
import { mapGetters, mapState } from "vuex";
import { database } from "../config/firebase";
import { errorMessage } from "@/services/MessageService";

export default {
  name: "Home",
  data() {
    return {
      databaseRef: null,
      totalSold: 0,
      totalOrders: 0,
      lastOrder: {
        created: null,
        full_payment: 0,
        total_products: 0,
        id: "NULL"
      },
      // :: Loaders
      loader: {
        chartSales: true,
        totalOrders: true,
        totalSold: true,
        lastOrder: true
      }
    };
  },
  components: {
    AppTotalSoldToday: TotalSoldToday,
    AppTotalOrdersToday: TotalOrdersToday,
    AppLastOrder: LastOrder,
    AppTotalSales: TotalSales,
    AppBranchOffice: BranchOffice,
    AppCalendar: Calendar,
    AppWelcome: Welcome
  },
  beforeMount() {
    this.getDashboardData();
  },
  beforeDestroy() {
    // :: Detach databaseRef listener
    this.databaseRef.off("value");
    this.databaseRef = null;
  },
  computed: {
    ...mapState("user", ["components", "data"]),
    ...mapGetters("pointSale", ["getLastOrder"]),
    ...mapState("business", ["currentBranchID"])
  },
  methods: {
    async getDashboardData() {
      try {
        this.databaseRef = await database(
          `branch_offices/${this.currentBranchID}/analytics/sales/today`
        );
        this.databaseRef.on("value", snapshot => {
          if (snapshot.exists()) {
            this.totalOrders = snapshot.val().total_orders;
            this.totalSold = snapshot.val().total_sold;
            if (this.getLastOrder) this.lastOrder = this.getLastOrder;
            const salesSnap = snapshot.val().sales_list;

            this.loader.totalOrders = false; // :: Finish load Total Orders
            this.loader.totalSold = false; // :: Finish load Total Sold
            this.loader.lastOrder = false; // :: Finish load Last Order

            // :: Transform Object to Array Object like {x: "", y: ""}
            if (salesSnap) {
              const sales = Object.keys(salesSnap).map(key => {
                return {
                  x: moment.unix(salesSnap[key].created).format("HH:mm"),
                  y: salesSnap[key].total_sold
                };
              });
              this.loader.chartSales = false; // :: Finish load Chart Sales
              // :: Update chart data
              this.$apexcharts.exec("vuechart-sales-today", "updateSeries", [
                { name: ["ventas"], data: sales }
              ]);
            }
            this.loader.chartSales = false; // :: Finish load Chart with empty values
          } else {
            this.loader.chartSales = false;
            this.loader.totalOrders = false;
            this.loader.totalSold = false;
            this.loader.lastOrder = false;
          }
        });
      } catch (error) {
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información del dashboard. Code:" +
            error
        });
        // :: Finish Loaders trycatch Error
        this.loader.chartSales = false;
        this.loader.totalOrders = false;
        this.loader.totalSold = false;
        this.loader.lastOrder = false;
      }
    },
    resetDashboardData() {
      this.loader.chartSales = true;
      this.loader.totalOrders = true;
      this.loader.totalSold = true;
      this.loader.lastOrder = true;
      this.totalSold = 0;
      this.totalOrders = 0;
      this.lastOrder = {
        created: null,
        full_payment: 0,
        total_products: 0
      };
      this.$apexcharts.exec("vuechart-sales-today", "updateSeries", [
        { name: ["ventas"], data: [{}] }
      ]);
    }
  }
};
</script>
