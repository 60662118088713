<template>
  <v-card width="100%" class="mx-auto">
    <div class="d-flex align-center pa-3">
      <h3 class="text-h6 font-weight-bold">Calendario</h3>
    </div>
    <v-date-picker
      elevation="4"
      width="100%"
      no-title
      color="primary"
      v-model="today"
      readonly
      event-color="green lighten-1"
    ></v-date-picker>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      today: moment().format("YYYY-MM-DD")
    };
  }
};
</script>
